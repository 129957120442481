<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card :class="!resuseComponent ? 'primary--border' :''" outlined>
          <v-card-title class="title" v-if="!resuseComponent">
            Personal Event List
            <v-spacer></v-spacer>
            <v-btn
              v-if="personalEvents.length >0"
              small
              class="white--text"
              outlined
              color="success"
              @click="downloadEvent()"
            >
            <v-icon left small>cloud_download</v-icon>
            
              Download
            </v-btn>
            &nbsp;&nbsp;
            <add-button
              permission="event-create"
              @action="displayModal = true"
            >
              New Personal Event
            </add-button>
          </v-card-title>
          <v-card outlined v-if="!resuseComponent">
            <v-card-title outlined>
              <v-flex xs3>
                <v-select
                  :items="status_types"
                  autocomplete="off"
                  label="Select Status"
                  outlined
                  dense
                  class="pa-0"
                  v-model="selected_status_type"
                ></v-select>
              </v-flex>
              <v-flex xs3>
                <v-calendar-field
                  id-val="from_date"
                  v-model="frmDate"
                  label="From Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs3>
                <v-calendar-field
                  id-val="to_date"
                  v-model="toDate"
                  label="To Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs2>
                <search-button permission="event-read" @action="get()">
                  Search
                </search-button>
              </v-flex>
            </v-card-title>
           
          </v-card>
           <v-card-text v-if="resuseComponent">
              <alert-message
                title="Personal Event"
                :type="'warning'"
                extra-small
                message="Please Select Event as completed or set reminder for tomorrow"
              ></alert-message>
            </v-card-text>
            <br>
          <!-- :items="form.items.data" -->
          <v-data-table
            :headers="headers"
            :items="personalEvents"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="meta_total"
            :hide-default-footer="resuseComponent ? true : false"
            item-key="id"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>
                  <v-checkbox @click="toogleEvent(item)" v-model="item.mark">
                  </v-checkbox>
                </td>
                <td>{{ ++index }}</td>
                <td class="text-xs-left">{{ item.description }}</td>

                <td class="text-xs-left" v-if="!resuseComponent">
                  {{ item.status }}
                </td>
                <td class="text-xs-left" v-if="!resuseComponent">
                  {{ item.personal_event_date }}
                </td>

                <td class="text-right" v-if="!resuseComponent">
                  <edit-button
                    permission="event-update"
                    @agree="setData(item)"
                  />
                  <delete-button
                    permission="event-delete"
                    @agree="deleteEvent(item.id)"
                  />
                  <!-- @agree="form.delete(item.id)" -->
                </td>
              </tr>
            </template>
          </v-data-table>
          <br />
          <v-container v-if="!resuseComponent">
            <v-card-title>
              <v-btn
                v-if="selectedEvents.length > 0"
                color="primary"
                style="margin-bottom: 5px"
                block
                large
                outlined
                @click="updateEventStatus()"
                
              >
              <!-- :disabled="selectedEvents.length == 0" -->
                Update Event Status
              </v-btn>
            </v-card-title>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="displayModal" persistent max-width="400px">
      <PersonalEvent @reset="reset()" :key="compKey" :edit_data="edit_data" />
      <!-- <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add/Update</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-10">
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-calendar-field
              :key="compKey"
              v-if="!reuseComponent"
              id-val="personal_event"
              v-model="form.personal_event_date"
              label="Personal Event *"
              name="event_date"
              :error-messages="form.errors.get('event_date')"
              :rules="[(v) => !!v || 'Date is required']"
              @click="compKey + 1"
            >
            </v-calendar-field>
            
            <v-textarea
              autocomplete="off"
              label="Description *"
              class="pa-0"
              outlined
              dense
              no-resize
              v-model="form.description"
              required
              name="description"
              :error-messages="form.errors.get('description')"
              :rules="[(v) => !!v || 'Description is required']"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" text @click="reset(), (save_flag = true)"
                >Close</v-btn
              >
              <v-btn color="success" text v-if="save_flag" @click="save()"
                >Save</v-btn
              >
              <v-btn color="success" text @click="save()" v-else>Update</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card> -->
    </v-dialog>
  </v-container>
</template>

<script>
import { redirectDownloadUrl } from '../../../../library/helpers';

import Mixins from "@/library/Mixins";
import PersonalEvent from "./personaleventform.vue";
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
export default {
  components: {
    PersonalEvent,
  },
  props: {
    resuseComponent: {
      default: false,
    },
    personalEventData: {
      default() {
        return [];
      },
    },
  },
  mixins: [Mixins],
  data: () => ({
    frmDate: "",
    toDate: "",
    selectedEvents: [],
    compKey: 0,
    deleteId: "",
    edit_data: "",
    displayModal: false,
    headers: [
      {},
      { text: "#", align: "left", value: "id", sortable: false },
      {
        text: "Description",
        align: "left",
        value: "description",
        sortable: false,
      },
      { text: "Status", align: "left", value: "status", sortable: false },
      {
        text: "Date",
        align: "left",
        value: "personal_event_date",
        sortable: false,
      },

      { text: "Action", align: "right", sortable: false },
    ],
    personalEvents: [],
    meta_total: 0,
    selected_status_type: "incomplete",
    status_types: [
      { text: "Completed", value: "completed" },
      { text: "Incomplete", value: "incomplete" },
      { text: "All", value: "all" },
    ],
    item_status: "",
  }),
  mounted() {
    if (this.resuseComponent) {
      this.headers = [
        {
          width: "2%",
        },
        { text: "#", align: "left", value: "id", sortable: false, width: "5%" },
        {
          text: "Description",
          align: "left",
          value: "description",
          sortable: false,
        },
      ];
    }
  },
  methods: {
    get() {
      if (!this.resuseComponent) {
        this.$rest
          .get(
            "/api/personal-event?status=" +
              this.selected_status_type +
              "&frm_date=" +
              this.frmDate +
              "&to_date=" +
              this.toDate +
              "&reuseComponent=" +
              this.resuseComponent
          )
          .then(({ data }) => {
            this.selectedEvents = [];
            this.personalEvents = data.data;
            this.meta_total = data.meta.total;
            this.pagination.totalItems = data.meta.total;
          });
      } else {
        this.personalEvents = this.personalEventData.data;
        this.meta_total = this.personalEventData.meta.total;
        this.pagination.totalItems = this.personalEventData.meta.total;
      }
    },
    reset() {
      this.displayModal = false;
      this.get();
    },
    setData(item) {
      this.compKey = this.compKey + 1;
      this.displayModal = true;
      this.edit_data = item;
    },
    deleteEvent(id) {
      this.$rest
        .delete("/api/personal-event/" + id)
        .then((res) => {
          if (res.status == 200) {
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
            this.get();
          }
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },
    toogleEvent(data) {
      if (this.selectedEvents.indexOf(data) > -1)
        this.selectedEvents.splice(this.selectedEvents.indexOf(data), 1);
      else this.selectedEvents.push(data);
      this.$emit("getId", this.selectedEvents);
    },
    updateEventStatus() {
      return this.$rest
        .post("/api/updtae-personal-event-status", {
          events: this.selectedEvents,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
            this.get();
          }
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },
    downloadEvent(){
      redirectDownloadUrl({
        uri:'/download/personal-event',
        queryString:"status=" +
              this.selected_status_type +
              "&frm_date=" +
              this.frmDate +
              "&to_date=" +
              this.toDate +
              "&reuseComponent=" +
              this.resuseComponent
      });
    },
  },
  watch: {
    pagination: function () {
      // if(!this.resuseComponent) 
      this.get();
    },
  },
};
</script>
